<template>
    <section class="slice bg-section-secondary">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 text-dark py-4 px-lg-0 mb-4">
                    <site-map-links title="Sidor" :documents="orderByTitle(pages)" />
                    <site-map-links title="Snabblån" :documents="orderByTitle(lenders.langivare)" />
                    <site-map-links title="Låneförmedlare" :documents="orderByTitle(lenders.laneformedlare)" />
                    <site-map-links title="Privatlån" :documents="orderByTitle(lenders.privatlan)" />
                    <site-map-links title="Artiklar" :documents="orderByPrismicTitle(articles)" />
                    <site-map-links title="Bloggar" :documents="orderByPrismicTitle(blogs)" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { orderBy } from 'lodash';
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';
import SiteMapLinks from '../../components/SiteMapLinks.vue';

export default {
    components: {
        SiteMapLinks
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {

        let lenders = {
            laneformedlare: [],
            privatlan: [],
            langivare: []

        };

        Object.values(this.$store.state.lenders).forEach(lender => {
            lenders[lender.data.slug_parent].push(lender);
        });
        return {
            pages: [],
            posts: [],
            lenders: lenders
        };
    },
    async fetch() {
        this.posts = await queryAll(
            this.$prismic.api,
            this. $prismic.predicates.at('document.type', 'post'),
            {}
        );

        this.pages = await queryAll(
            this.$prismic.api,
            this. $prismic.predicates.at('document.type', 'page'),
            {}
        );
    },
    computed: {
        blogs() {
            return this.posts.filter(p => p.data.category === 'blogg');
        },
        articles() {
            return this.posts.filter(p => p.data.category === 'artiklar');
        }
    },
    methods: {
        orderByTitle(documents) {
            return orderBy(documents, [document => document.data.title], ['asc']);
        },
        orderByPrismicTitle(documents) {
            return orderBy(documents, [document => this.$prismic.asText(document.data.title)], ['asc']);
        }
    }
};
</script>