<template>
    <div class="card">
        <div class="card-header py-4 collapsed" @click="toggle">
            <h6 class="mb-0">
                <i class="fa fa-question-circle mr-3" />
                {{ $prismic.asText(iterator.question) }}
            </h6>
        </div>
        <div v-show="open">
            <div class="card-body" v-html="$prismic.asHtml(iterator.answer)" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        iterator: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            open: false
        };
    },
    methods: {
        toggle() {
            this.open = ! this.open;
        }
    }
};
</script>