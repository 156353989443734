<template>
    <div class="main-content">
        <Slices
            :slices="document.data.body"
        />

        <section 
            v-if="author"
            class="slice slice-xl"
        >
            <div class="container card py-5 px-5">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-2">
                                <prismic-image :img="author.data.image" w="50" class="lazy avatar entered loaded" />
                            </div>
                            <div class="col-10 pl-4">
                                <p class="pb-0 mb-2">
                                    <strong class="lead pb-0 mb-0 font-weight-bold text-primary">{{ author.data.name }}</strong>
                                </p>
                                <hr class="m-0 p-0">
                                <p class="small mt-2">{{ $prismic.asText(author.data.description) }}</p>
                                <span class="badge badge-light">Uppdaterad {{ $format.date(document.last_publication_date) }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="sources.length > 0" class="col-lg-6">
                        <strong>Källor:</strong>
                        <ul class="list-unstyled">
                            <li v-for="(iterator, index) in sources" :key="`source${index}`">
                                <a 
                                    :href="iterator.source"
                                    target="_blank"
                                >
                                    {{ iterator.source }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Slices from '@/components/slices/Slices.vue';

export default {
    components: { 
        Slices 
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        author() {
            if (! this.document.data.author.id) {
                return false;
            }

            return this.$store.state.authors[this.document.data.author.id];
        },
        sources() {
            return this.document.data.sources.filter(s => s.source);
        }
    },
};
</script>
