<template>
    <section class="bg-white pt-100 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 bg-transparent py-5 text-center">
                    <h3 class="display-4"><code class="text-primary">{Fusklapp}</code></h3>
                </div>
                <div class="col-lg-3" />
                <div class="col-lg-6">
                    <table class="table table-cards">
                        <thead>
                            <tr>
                                <th class="font-weight-bolder">Rubrik</th>
                                <th class="font-weight-bolder">Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(detail, index) in details">
                                <details-row :key="`row${index}`" :title="detail.title" :value="detail.value" />
                                <tr :key="`divider${index}`" class="table-divider" />
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-3" />
            </div>
        </div>
    </section>
</template>
<script>
import DetailsRow from './DetailsRow.vue';

export default {
    components: {
        DetailsRow
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        details() {

            const details = [
                { title: 'Namn', value: this.document.data.title }
            ];

            if (this.model.website) {
                details.push({
                    title: 'Url', value: this.model.website
                }); 
            }

            details.push(
                { title: 'Ägare', value: this.model.company.name },
                { title: 'Lånebelopp', value: this.model.amountString },
                { title: 'Ränta', value: this.model.interestString },
                { title: 'Effektiv ränta', value: this.model.effectiveInterestString },
                { title: 'Låneperiod', value: this.model.getDurationString() },
                { title: 'Kreditupplysning', value: this.model.amountString },
                { title: 'Rapporterar lån till UC', value: this.model.takesUC ? 'Ja' : 'Nej' },
                { title: 'Accepterar betalningsanmärkningar', value: this.model.acceptsPaymentRemarks ? 'Ja' : 'Nej' }
            );

            if (this.model.acceptsPaymentRemarks && this.model.paymentRemarksMaxCount) {
                details.push({
                    title: 'Kommentar till anmärkning',
                    value: `Max ${this.model.paymentRemarksMaxCount} st`
                });
            }

            details.push(
                { title: 'Avgift, uppläggning', value: this.model.setupFeeString },
                { title: 'Avgift, faktura', value: this.model.administrationFeeString },
                { title: 'Avgift, sen betalning', value: this.model.latePaymentFeeString },
                { title: 'Kontakt, e-post', value: this.model.company.email },
                { title: 'Kontakt, telefon', value: this.model.company.phone },
                { title: 'Adress', value: `${this.model.company.addres} , ${this.model.company.postalNumber} ${this.model.company.county}` },
                { title: 'Minsta ålder för ansökan', value: `${this.model.minAge} år` },
            );

            return details;
        }
    }
};
</script>

<style lang="scss" scoped>
    div.col-lg-8 {
        font-size: 1.1em !important;

        & .table {
            margin-top: 40px;

            & :first-child {
                margin-top: 0;
            }
        }

        & .table td, .table th {
            font-weight: normal;
            font-size: 1.0em !important;

        }
    }
</style>