<template>
    <section v-if="document.data.content.length" class="bg-light pb-5">
        <div class="container">
            <div class="row">
                <div class="col text-center pb-5 pt-5">
                    <h3 class="display-4">Bra att veta om <strong>{{ document.data.title }}</strong></h3>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-9 bg-white description card py-5 px-5 order-lg-1 text-dark" v-html="$prismic.asHtml(document.data.content)" />
            </div>
        </div>
    </section>
    <section v-else class="bg-light pt-5 pb-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9 bg-white description card py-5 px-5 order-lg-1 text-dark">
                    Mer information kommer snart.
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    },
};
</script>