<template>
    <div class="row">
        <div v-if="lender.data.redirect_enabled" class="col-6 bg-gradient-dark">
            <out-link :document="lender" :text="outLinkText" class="btn btn-block btn-sm py-3 text-white" />
        </div>
        <div class="col bg-gradient-light">
            <nuxt-link :to="$prismic.linkResolver(lender)" class="btn btn-block btn-sm py-3 ml-0 pl-0">
                {{ reviewLinkText }}
            </nuxt-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lender: {
            type: Object,
            required: true
        },
        outLinkText: {
            type: String,
            required: false,
            default: 'Ansök direkt'
        },
        reviewLinkText: {
            type: String,
            required: false,
            default: 'Läs mer'
        }
    }
};
</script>