var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"slice bg-kreditly-light mb-5",staticStyle:{"padding-top":"73.1875px"},attrs:{"data-offset-top":"#header-main"}},[_c('div',{staticClass:"container py-lg-5 py-5 pt-lg-7 position-relative zindex-100"},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-lg-6"},[_c('h1',{staticClass:"display-3 mb-4"},[_vm._v(_vm._s(_vm.document.data.title))]),_vm._v(" "),(_vm.model.isBroker)?_c('h3',{staticClass:"font-weight-light"},[_vm._v("\n                    Hos "+_vm._s(_vm.document.data.title)+" kan du få erbjudande från hela "+_vm._s(_vm.model.connectedBanks)+" banker.\n                ")]):_c('h3',[_vm._v("\n                    Låna "),_c('b',[_vm._v(_vm._s(_vm.model.amountString))]),_vm._v(" med en ränta på "),_c('b',[_vm._v(_vm._s(_vm.model.interestString))]),_vm._v(".\n                ")]),_vm._v(" "),_c('out-link',{staticClass:"btn btn-md btn-primary rounded-pill mt-4",attrs:{"document":_vm.document}})],1),_vm._v(" "),_c('div',{staticClass:"col-lg-5 mt-5 mt-md-0"},[_c('table',{staticClass:"table table-cards"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_c('prismic-image',{attrs:{"img":_vm.document.data.logo,"alt":((_vm.document.data.title) + " logo"),"h":"40"}})],1)])]),_vm._v(" "),_c('tbody',_vm._l(([
                                { header: 'Belopp', data: _vm.model.amountString },
                                { header: 'Ränta', data: _vm.model.interestString },
                                { header: 'Löptid', data: _vm.model.getDurationString() },
                                { header: 'Åldersgräns', data: _vm.model.minAge },
                                { header: 'Kreditupplysning', data: _vm.$getCreditCheckString(_vm.model.creditCheck) },
                                { header: 'Betalningsanmärkning', data: _vm.model.acceptsPaymentRemarks ? 'Accepteras' : 'Accepteras inte' } ].concat(_vm.model.isBroker ? [{
                                header: 'Anslutna banker',
                                data: _vm.model.connectedBanks
                            }] : [])),function(row,index){return _c('tr',{key:index},[_c('td',{staticClass:"font-weight-bolder text-right",attrs:{"width":"50%"}},[_vm._v(_vm._s(row.header))]),_vm._v(" "),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(row.data))])])}),0)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }