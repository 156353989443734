<template>
    <tr>
        <th>{{ title }}</th>
        <td>{{ value }}</td>
    </tr>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },

        value: {
            type: String,
            required: true
        }
    }  
};
</script>