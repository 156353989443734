<template>
    <div class="row text-dark pb-1">
        <div class="col-6 text-left border-bottom text-sm font-weight-bold">{{ title }}</div>
        <div class="col-6 text-right border-bottom text-sm">{{ value }}</div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
    }
};
</script>