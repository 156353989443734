<template>
    <section class="slice my-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 text-dark" v-html="$prismic.asHtml(slice.primary.content)" />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>