<template>
    <section class="slice slice-lg bg-section-secondary overflow-hidden">
        <div class="container position-relative zindex-100">
            <div class="mb-5 px-3 text-center">
                <span class="badge badge-soft-success badge-pill badge-lg">{{ slice.primary.label }}</span> 
                <h3 class="mt-4">{{ $prismic.asText(slice.primary.header) }}</h3>
                <div class="fluid-paragraph mt-3">
                    <p class="lead lh-180 text-dark" v-html="$prismic.asHtml(slice.primary.teaser)" />
                    <span class="static-rating static-rating-sm"> 
                        <i v-for="i in 5" :key="i" class="star fas fa-star voted" />
                    </span> 
                </div>
            </div>
            <div class="row">
                <div v-for="(box, index) in slice.items" :key="`featuredcontentbox${index}`" class="col-lg-4">
                    <div class="card px-3">
                        <div class="card-body py-5">
                            <div class="d-flex align-items-center">
                                <div class="icon bg-gradient-primary text-white rounded-circle icon-shape shadow-primary"> 
                                    <i :class="box.icon" /> 
                                </div>
                                <div class="icon-text pl-4">
                                    <h5 class="mb-0">{{ $prismic.asText(box.header) }}</h5>
                                </div>
                            </div>
                            <p class="mt-4 mb-0" v-html="$prismic.asHtml(box.content)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>