<template>
    <nuxt-link :to="$prismic.linkResolver(lender)"> 
        <prismic-image 
            :alt="lender.data.title"
            :img="lender.data.logo" 
            w="200"
            class="lender-logo mt-3 entered loaded"
        />
    </nuxt-link> 
</template>
<script>
export default {
    props: {
        lender: {
            type: Object,
            required: true
        }
    }
};
</script>