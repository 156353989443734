<template>
    <div class="main-content">
        <lender-banner :document="document" :model="model" />
        <lender-info :document="document" :model="model" />
        <lender-content :document="document" />
        <lender-details :document="document" :model="model" />
    </div>
</template>
<script>

import LenderInfo from './LenderInfo.vue';
import LenderContent from './LenderContent.vue';
import LenderDetails from './LenderDetails.vue';
import LenderBanner from './LenderBanner.vue';
export default {
    components: {
        LenderInfo,
        LenderContent,
        LenderDetails,
        LenderBanner
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },  
};
</script>
