import { replace as replaceHelper } from '@swegaming-ab/nuxtjs-helpers';

export default async function($prismic, path, error, documentKey = 'slug') {

    const documentHelper = findDocumentHelper(path, documentKey);

    if (documentHelper) {

        const fetchLinks = [].concat(getFetchLinks(documentHelper.pageType));

        let document = await $prismic.api.getByID(documentHelper.id, {
            fetchLinks: fetchLinks
        });

        document = replaceHelper(document, document.lang);

        return {
            document: document
        };
    }

    return error({
        statusCode: 404,
        message: 'Not found'
    });
}

function getFetchLinks(pageType) {
    return {}[pageType] || [];
}

function findDocumentHelper(path, key = 'slug') {

    if (path.slice(-1) !== '/') {
        path = `${path}/`;
    }

    const documents = require('@/documents.json');
    for (const [, document] of Object.entries(documents)) {
        if (document[key] === path) {
            return document;
        }
    }
    return false;
}