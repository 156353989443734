<template>
    <section class="slice bg-kreditly-light mb-5" data-offset-top="#header-main" style="padding-top: 73.1875px;">
        <div class="container py-lg-5 py-5 pt-lg-7 position-relative zindex-100">
            <div class="row justify-content-between">
                <div class="col-lg-6">
                    <h1 class="display-3 mb-4">{{ document.data.title }}</h1>
                    <h3 
                        v-if="model.isBroker"
                        class="font-weight-light"
                    >
                        Hos {{ document.data.title }} kan du få erbjudande från hela {{ model.connectedBanks }} banker.
                    </h3>
                    <h3 v-else>
                        Låna <b>{{ model.amountString }}</b> med en ränta på <b>{{ model.interestString }}</b>.
                    </h3>                    
                    <out-link :document="document" class="btn btn-md btn-primary rounded-pill mt-4" />
                </div>
                <div class="col-lg-5 mt-5 mt-md-0">
                    <table class="table table-cards">
                        <thead>
                            <tr>
                                <th colspan="2" class="text-center">
                                    <prismic-image :img="document.data.logo" :alt="`${document.data.title} logo`" h="40" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="(row, index) in [
                                    { header: 'Belopp', data: model.amountString },
                                    { header: 'Ränta', data: model.interestString },
                                    { header: 'Löptid', data: model.getDurationString() },
                                    { header: 'Åldersgräns', data: model.minAge },
                                    { header: 'Kreditupplysning', data: $getCreditCheckString(model.creditCheck) },
                                    { header: 'Betalningsanmärkning', data: model.acceptsPaymentRemarks ? 'Accepteras' : 'Accepteras inte' },
                                ].concat(model.isBroker ? [{
                                    header: 'Anslutna banker',
                                    data: model.connectedBanks
                                }] : [])" 
                                :key="index"
                            >
                                <td width="50%" class="font-weight-bolder text-right">{{ row.header }}</td>
                                <td class="text-left">{{ row.data }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>       
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
};
</script>