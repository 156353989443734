<template>
    <section class="slice">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 py-5 pb-200 text-dark px-5 px-lg-0 pr-lg-5" v-html="col1Html" />
                <div class="col-lg-6 py-5 pb-200 text-dark px-5 px-lg-0 pr-lg-5" v-html="col2Html" />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        col1Html() {

            if (this.slice.primary.col1_h2.length > 0) {
                return `
                    <h2 class="display-4 mb-4">${this.$prismic.asText(this.slice.primary.col1_h2)}</h2>
                    ${this.$prismic.asHtml(this.slice.primary.col1_content)}
                `;
            }
            return this.$prismic.asHtml(this.slice.primary.col1_content);
        },
        col2Html() {

            if (this.slice.primary.col2_h2.length > 0) {
                return `
                    <h2 class="display-4 mb-4">${this.$prismic.asText(this.slice.primary.col2_h2)}</h2>
                    ${this.$prismic.asHtml(this.slice.primary.col2_content)}
                `;
            }
            return this.$prismic.asHtml(this.slice.primary.col2_content);
        }
    }
};
</script>