<template>
    <div>
        <h3 class="mt-5">{{ title }}</h3>
        <nuxt-link
            v-for="document in documents"
            :key="document.id"
            :to="$prismic.linkResolver(document)"
            class="d-block mb-1 lead"
        >
            {{ getTitle(document.data.title) || 'Not set' }}
        </nuxt-link>
    </div>
</template>

<script>

export default {
    props: {
        documents: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },       
    },
    methods: {
        getTitle(title){
            if (Array.isArray(title)) {
                return this.$prismic.asText(title);
            }
            return title;
        }
    }
};
</script>
