<template>
    <section class="bg-white slice">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 py-0 px-5 px-lg-0 pr-lg-5 text-dark" v-html="html" />

                <div v-if="slice.primary.enable_image" class="col-lg-4 d-none d-lg-block m-0 p-0" :style="`background: url('${slice.primary.image.url}&w=400'); background-repeat: no-repeat;`" />
                <div v-else-if="slice.primary.enable_right" class="col-lg-4 bg-danger py-5 px-5 text-white shadow shadow-lg">
                    <h2 class="mb-4 text-white">
                        <i class="fa fa-exclamation-triangle" /> {{ $prismic.asText(slice.primary.header_right) }}
                    </h2>
                    <div v-html="$prismic.asHtml(slice.primary.content_right)" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        html() {
            return `
                <h2 class="display-4 mb-4">${this.$prismic.asText(this.slice.primary.h2)}</h2>
                ${this.$prismic.asHtml(this.slice.primary.content)}
            `;
        }
    }
};
</script>

<style lang="scss" scoped>
    ::v-deep li {
        margin-bottom: 10px;
        padding-left: 10px;
    }

    ::v-deep li::marker {    
        font-family: 'FontAwesome';
        content: '\f058';
        color: #36b37e;
    }

    ::v-deep .text-white a {
        color: white !important;
        text-decoration: underline;
    }


    h2 {
        @extend .display-4 !optional;
        @extend .mb-4 !optional;
    }
</style>