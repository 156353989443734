<template>
    <section class="spotlight bg-cover bg-size--cover" data-offset-top="#header-main" style="background-image: url(&quot;/bg_omoss.jpeg&quot;); padding-top: 73.1875px; margin-top: 73.1875px;">
        <span class="mask bg-dark opacity-9" />
        <div class="spotlight-holder pt-9 pb-6 py-lg-0" style="height: 450px;">
            <div class="container d-flex align-items-center px-0">
                <div class="col">
                    <div class="row row-grid">
                        <div class="col-lg-6">
                            <div class="py-6 text-center text-lg-left">
                                <h1 class="display-4 text-white mb-4">{{ $prismic.asText(slice.primary.h1) }}</h1>
                                <p class="lead lh-180 text-white">{{ slice.primary.p }}</p>
                                <div v-if="slice.primary.enable_link" class="mt-5">
                                    <nuxt-link :to="$prismic.linkResolver(slice.primary.link)" class="btn btn-white rounded-pill px-5 mr-lg-4 box-shadow-2">
                                        {{ slice.primary.link_text }}
                                    </nuxt-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>