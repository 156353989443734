<template>
    <section class="slice pb-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 py-5 px-5 text-white bg-success" v-html="pros" />
                <div class="col-lg-6 py-5 px-5 text-white bg-danger" v-html="cons" />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        pros() {
            return `
                <h2 class="mb-4 text-white"><i class="fa fa-plus-circle"></i> Fördelar</h2>
                ${this.$prismic.asHtml(this.slice.primary.pros)}
            `;
        },
        cons() {
            return `
                <h2 class="mb-4 text-white"><i class="fa fa-minus-circle"></i> Nackdelar</h2>
                ${this.$prismic.asHtml(this.slice.primary.cons)}
            `;
        }
    }
};
</script>