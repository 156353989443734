<template>
    <div class="col-lg-4">
        <div class="card hover-translate-y-n10 hover-shadow-lg border">
            <lender-card-header :lender="lender" />
            <div class="card-body py-4 text-center">
                <nuxt-link :to="$prismic.linkResolver(lender)" class="d-block h5">
                    {{ lender.data.title }}
                </nuxt-link> 
                <span class="font-weight-bolder small pb-3 d-block">{{ model.company.name }}</span> 

                <lender-table-row title="Lånebelopp, lägsta" :value="model.minAmountString" />
                <lender-table-row title="Lånebelopp, högsta" :value="model.maxAmountString" />
                <lender-table-row title="Ränta" :value="model.interestString" />
                <lender-table-row title="Upplysning via" :value="$getCreditCheckString(model.creditCheck)" />
                <lender-table-row-badge title="Betalningsanmärkning" :is-success="model.acceptsPaymentRemarks" success-text="Accepteras" danger-text="Accepteras ej" />
                <lender-table-row title="Åldersgräns" :value="`${model.minAge} år`" />
                <lender-table-row title="Uppläggningsavgift" :value="model.setupFeeString" />
                <lender-table-row title="Fakturaavgift" :value="model.administrationFeeString" />
            </div>
            <div class="card-footer px-0 py-0 border-0 overflow-hidden">
                <lender-buttons :lender="lender" />
                <high-cost-credit v-if="model.isHighCostCredit" />
            </div>
        </div>
    </div>
</template>
<script>
import LenderCardHeader from './LenderCardHeader.vue';
import LenderTableRow from './LenderTableRow.vue';
import LenderButtons from './LenderButtons.vue';
import HighCostCredit from './HighCostCredit.vue';
import LenderTableRowBadge from './LenderTableRowBadge.vue';

export default {
    components: {
        LenderCardHeader,
        LenderTableRow,
        LenderButtons,
        HighCostCredit,
        LenderTableRowBadge
    },
    props: {
        lenderId: {
            type: String,
            required: true
        }
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.lenderId];
        },
        model() {
            return new this.$models.Loan(this.lender);
        }
    }
};
</script>