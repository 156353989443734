<template>
    <section class="slice" data-offset-top="#header-main" style="padding-top: 73.1875px;">
        <div class="container py-lg-5 py-5 pt-lg-7 position-relative zindex-100">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <prismic-image v-if="slice.primary.image && slice.primary.image.url" :img="slice.primary.image" w="250" />
                    <h1 class="display-2">{{ $prismic.asText(slice.primary.h1) }}</h1>
                    <h3 class="font-weight-light" v-html="$prismic.asHtml(slice.primary.h4)" />
                    <nuxt-link 
                        v-for="iterator in slice.items.filter(i => i.link_document && i.link_document.id)" 
                        :key="iterator.link_document.id" 
                        :to="$prismic.linkResolver(iterator.link_document)"
                        class="btn btn-sm btn-primary rounded-pill mt-3"
                    >
                        {{ iterator.link_text }}
                    </nuxt-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>