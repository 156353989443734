<template>
    <div class="main-content">
        <section class="slice" data-offset-top="#header-main">
            <div class="container py-lg-5 py-5 pt-lg-7 position-relative zindex-100">
                <div class="row">
                    <div class="col-lg-6">
                        <prismic-image :img="document.data.image" class="lazy img-fluid entered loaded" w="540" />
                    </div>
                    <div class="col-lg-6">
                        <h1 class="font-weight-bolder">{{ $prismic.asText(document.data.title) }}</h1>
                        <span class="badge badge-soft-dark">{{ $format.date(document.first_publication_date) }}</span> 
                        <p class="lead text-dark">{{ $prismic.asText(document.data.preamble) }}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice bg-section-secondary">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 content text-dark" v-html="$prismic.asHtml(document.data.content)" />
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    },
};
</script>

<style lang="scss" scoped>

    
    ::v-deep pre {
        white-space: pre-wrap;       /* css-3 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }
</style>