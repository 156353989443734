<template>
    <section class="slice slice-xl has-floating-items bg-gradient-primary">
        <div class="container text-center">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-white">{{ $prismic.asText(slice.primary.header) }}</h3>
                    <div class="row text-center mt-4 justify-content-md-center">
                        <div class="col-lg-8">
                            <p class="lead text-white" v-html="$prismic.asHtml(slice.primary.teaser)" />
                        </div>
                    </div>
                    <div class="row">
                        <div v-for="(column, ci) in columns" :key="`column${ci}`" class="col-lg-6 text-left mt-3">
                            <div :id="`accordion-${ci}`" class="accordion accordion-stacked">
                                <template v-for="(iterator, qi) in column">
                                    <question-and-answer :key="qi" :iterator="iterator" />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="slice.primary.show_icons" class="container floating-items">
            <div class="icon-floating bg-white floating"> <span /> <img alt="Image placeholder" data-src="@/assets/img/icons/linear/basic/basic_life_buoy.png" class="lazy entered loaded" data-ll-status="loaded" src="@/assets/img/icons/linear/basic/basic_life_buoy.png"> </div>
            <div class="icon-floating icon-lg bg-white floating"> <span /> <img alt="Image placeholder" data-src="@/assets/img/icons/linear/basic/basic_question.png" class="lazy entered loaded" data-ll-status="loaded" src="@/assets/img/icons/linear/basic/basic_question.png"> </div>
            <div class="icon-floating icon-sm bg-white floating"> <span /> <img alt="Image placeholder" data-src="@/assets/img/icons/linear/basic/basic_magnifier.png" class="lazy entered loaded" data-ll-status="loaded" src="@/assets/img/icons/linear/basic/basic_magnifier.png"> </div>
            <div class="icon-floating icon-lg bg-white floating"> <span /> <img alt="Image placeholder" data-src="@/assets/img/icons/linear/basic/basic_spread_text_bookmark.png" class="lazy entered loaded" data-ll-status="loaded" src="@/assets/img/icons/linear/basic/basic_spread_text_bookmark.png"> </div>
            <div class="icon-floating bg-white floating"> <span /> <img alt="Image placeholder" data-src="@/assets/img/icons/linear/arrows/arrows_check.png" class="lazy entered loaded" data-ll-status="loaded" src="@/assets/img/icons/linear/arrows/arrows_check.png"> </div>
            <div class="icon-floating icon-sm bg-white floating"> <span /> <img alt="Image placeholder" data-src="@/assets/img/icons/linear/basic/basic_headset.png" class="lazy entered loaded" data-ll-status="loaded" src="@/assets/img/icons/linear/basic/basic_headset.png"> </div>
        </div>
    </section>
</template>
<script>
import QuestionAndAnswer from '@/components/QuestionAndAnswer.vue';
export default {
    components: {
        QuestionAndAnswer
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        columns() {
            const firstColumnLength = Math.ceil(this.slice.items.length / 2);
            return [
                this.slice.items.slice(0, firstColumnLength),
                this.slice.items.slice(firstColumnLength)
            ];
        }
    }
};
</script>