<template>
    <tr>
        <th>{{ title }}</th>
        <td class="text-right" v-html="value" />
    </tr>    
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },

        value: {
            type: String,
            required: true
        },
    }
};
</script>


