<template>
    <section class="slice bg-section-secondary">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div class=" text-dark py-4 px-5 px-lg-0 mb-4 text-left">
                        <h3>{{ $prismic.asText(slice.primary.h3) }}</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div 
                    v-for="iterator in $store.state.footer.site_wide_links"
                    :key="iterator.link_page.id"
                    class="col-lg-3"
                >
                    <div class="card rounded-0 shadow shadow-dark-sm">
                        <nuxt-link 
                            
                            :to="$prismic.linkResolver(iterator.link_page)"
                        >
                            <div class="card-body text-center mt-3">
                                <p class="lead font-weight-bolder text-dark">{{ iterator.link_text }}</p>
                            </div>
                        </nuxt-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>