<template>
    <page
        v-if="document.type === 'page'"
        :document="document"
    />
    <lender 
        v-else-if="document.type === 'lender'"
        :document="document" 
        :model="model"
    />
    <post 
        v-else-if="document.type === 'post'"
        :document="document" 
    />
</template>
<script>
// Pages
import getDocument from '@/plugins/get-document';
import Page from '@/components/pages/Page.vue';
import Lender from '@/components/pages/lender/Lender.vue';
import Post from '@/components/pages/Post.vue';

export default {
    components: {
        Page,
        Lender,
        Post
    },
    layout: 'site',

    /**
    * asyncData used on server side (only dev)
    */
    async asyncData({$prismic, route, error}) {
        try {
            return await getDocument($prismic, route.path, error);
        } 
        catch (e) {
            error({statusCode: 404, message: 'Not found'});
        }
    },
    head() {
        return {
            title: this.seoTitle,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.seoDescription
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: this.$prismic.linkResolver(this.document, true)
                },
                // {
                //     rel: 'shortcut icon',
                //     href: this.$store.state.settings.favico.small.url
                // }
            ]
        };
    },
    computed: {
        isLender() {
            return this.document.type === 'lender';
        },
        model() {
            if (this.isLender) {
                return new this.$models.Loan(this.document);
            }

            return false;
        },
        seoTitle() {
            if (this.isLender) {
                return `${this.document.data.title} » Låna ${this.model.amountString} 
                    (${this.model.interestString}) | Kreditly`;
            }

            return this.document.data.seo_title;
        },
        seoDescription() {
            if (this.isLender) {
                return `${this.document.data.title} erbjuder lån på ${this.model.amountString} 
                    med löptider på ${this.model.getDurationString()} till en ränta 
                    på ${this.model.interestString}. Läs vårt omdöme om ${this.document.data.title} 
                    här på Kreditly.`;
            }

            return this.document.data.seo_description;
        }
    }
};
</script>

<style lang="scss" scoped>
    ::v-deep .text-white a {
        color: white !important;
        text-decoration: underline;
    }
</style>