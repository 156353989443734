<template>
    <section class="pb-100 pt-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <p class="text-uppercase font-weight-bolder">{{ slice.primary.header }}</p>
                </div>
            </div>
            <div class="row justify-content-center">
                <div 
                    v-for="iterator in slice.items"
                    :key="iterator.id"
                    class="col-6 col-lg-2"
                >
                    <nuxt-link :to="$prismic.linkResolver(iterator.link)">
                        <div class="card rounded-0 shadow shadow-light-md hover-translate-y-n10">
                            <div class="card-body text-center">
                                <div :class="`icon ${iterator.bootstrap_bg_class} text-white-50 rounded-circle icon-shape`"> <i :class="`fa ${iterator.fa_icon}`" /> </div>
                                <h6 class="mt-3">{{ iterator.link_text }}</h6>
                            </div>
                        </div>
                    </nuxt-link>
                </div>
            </div>
            <div class="fluid-paragraph text-center mt-2">
                <p>{{ slice.primary.under_links }}</p>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>