<template>
    <section class="slice slice-xl bg-gradient-info pb-250">
        <div class="bg-absolute-cover bg-size--contain d-flex align-items-center">
            <figure class="w-100">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1956 1143 1385 601" class="injected-svg svg-inject">
                    <g id="Group_243" data-name="Group 243" transform="translate(-1838 1118)">
                        <rect id="Rectangle_689" data-name="Rectangle 689" class="cls-1" width="89" height="75" transform="translate(54 476)" />
                        <rect id="Rectangle_690" data-name="Rectangle 690" class="cls-1" width="89" height="75" transform="translate(333 140)" />
                        <rect id="Rectangle_691" data-name="Rectangle 691" class="cls-1" width="89" height="75" transform="translate(479 200)" />
                        <rect id="Rectangle_692" data-name="Rectangle 692" class="cls-1" width="89" height="75" transform="translate(493 327)" />
                        <rect id="Rectangle_694" data-name="Rectangle 694" class="cls-1" width="89" height="75" transform="translate(280 364)" />
                        <rect id="Rectangle_695" data-name="Rectangle 695" class="cls-1" width="89" height="75" transform="translate(100 354)" />
                        <rect id="Rectangle_696" data-name="Rectangle 696" class="cls-1" width="89" height="75" transform="translate(493 41)" />
                        <rect id="Rectangle_697" data-name="Rectangle 697" class="cls-1" width="89" height="75" transform="translate(10 39)" />
                        <rect id="Rectangle_698" data-name="Rectangle 698" class="cls-1" width="89" height="75" transform="translate(325 25)" />
                        <rect id="Rectangle_699" data-name="Rectangle 699" class="cls-1" width="89" height="75" transform="translate(964 514)" />
                        <rect id="Rectangle_703" data-name="Rectangle 703" class="cls-1" width="89" height="75" transform="translate(659 539)" />
                        <rect id="Rectangle_705" data-name="Rectangle 705" class="cls-1" width="89" height="75" transform="translate(945 354)" />
                        <rect id="Rectangle_707" data-name="Rectangle 707" class="cls-1" width="89" height="75" transform="translate(855 39)" />
                        <rect id="Rectangle_700" data-name="Rectangle 700" class="cls-1" width="89" height="75" transform="translate(1178 140)" />
                        <rect id="Rectangle_704" data-name="Rectangle 704" class="cls-1" width="89" height="75" transform="translate(1125 364)" />
                        <rect id="Rectangle_708" data-name="Rectangle 708" class="cls-1" width="89" height="75" transform="translate(1170 25)" />
                        <rect id="Rectangle_709" data-name="Rectangle 709" class="cls-1" width="89" height="75" transform="translate(212 238)" />
                        <rect id="Rectangle_710" data-name="Rectangle 710" class="cls-1" width="89" height="75" transform="translate(123 154)" />
                        <rect id="Rectangle_713" data-name="Rectangle 713" class="cls-1" width="89" height="75" transform="translate(390 551)" />
                        <rect id="Rectangle_714" data-name="Rectangle 714" class="cls-1" width="89" height="75" transform="translate(884 178)" />
                        <rect id="Rectangle_715" data-name="Rectangle 715" class="cls-1" width="89" height="75" transform="translate(683 378)" />
                        <rect id="Rectangle_693" data-name="Rectangle 693" class="cls-1" width="89" height="75" transform="translate(-118 413)" />
                    </g>
                </svg>
            </figure>
        </div>
        <div class="container position-relative zindex-100">
            <div class="row">
                <div class="col-lg-8 text-white px-5 px-lg-0">
                    <span 
                        v-if="slice.primary.text_above_badge" 
                        class="badge badge-soft-primary mb-3"
                    >
                        {{ slice.primary.text_above_badge }}
                    </span>
                    <div 
                        v-if="slice.primary.text_above.length" 
                        class="pt-3 lead" 
                        v-html="$prismic.asHtml(slice.primary.text_above)" 
                    />
                    <h2 class="display-4 mb-4 text-white">
                        <i :class="slice.primary.fa_icon || 'fa fa-question-circle'" /> {{ $prismic.asText(slice.primary.h2) }}
                    </h2>
                    <div v-html="$prismic.asHtml(slice.primary.content)" />
                </div>
            </div>
        </div>
        <div class="shape-container" data-shape-position="bottom" style="height: 73px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 220" preserveAspectRatio="none" class="ie-shape-wave-3">
                <path d="M918.34,99.41C388.23,343.6,47.11,117.12,0,87.54V220H1600V87.54C1378.72-76.71,1077.32,27.41,918.34,99.41Z" />
            </svg>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
};
</script>