<template>
    <div class="row text-dark pb-1">
        <div class="col text-left border-bottom text-sm font-weight-bold">{{ title }}</div>
        <div class="col text-right border-bottom text-sm">
            <span class="badge" :class="{ 'badge-soft-success': isSuccess, 'badge-soft-danger': ! isSuccess }">
                {{ isSuccess ? successText : dangerText }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        isSuccess: {
            type: Boolean,
            required: true
        },
        successText: {
            type: String, 
            required: true
        },
        dangerText: {
            type: String,
            required: true
        }
    }
};
</script>