<template>
    <section class="bg-white slice">
        <div class="container">
            <div 
                v-for="(iterator, index) in slice.items" 
                :key="index" 
                class="row py-5"
            >
                <template v-if="index % 2 === 0">
                    <div class="col-lg-6 text-dark" v-html="getContentHtml(iterator)" />
                    <div class="col-lg-6 text-center pt-100 d-none d-lg-block"> 
                        <span 
                            v-if="iterator.image.url" 
                            class="rounded bg-gradient-neutral py-5 px-5 d-inline-block perspective-right shadow"
                        >
                            <prismic-image :img="iterator.image" w="72" />
                        </span> 
                    </div>
                </template>
                <template v-else>
                    <div class="col-lg-6 text-center pt-100 d-none d-lg-block"> 
                        <span 
                            v-if="iterator.image.url" 
                            class="rounded bg-gradient-neutral py-5 px-5 d-inline-block perspective-right shadow"
                        >
                            <prismic-image :img="iterator.image" w="72" />
                        </span> 
                    </div>
                    <div class="col-lg-6 text-dark" v-html="getContentHtml(iterator)" />
                </template>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    methods: {
        getContentHtml(iterator) {

            return `
                    <h2 class="display-4 mb-4">${this.$prismic.asText(iterator.h2)}</h2>
                    ${this.$prismic.asHtml(iterator.content)}
            `;
        }
    }
};
</script>