<template>
    <div class="slices">
        <template v-for="(slice, index) in slices">
            <component
                :is="toPascalCase(slice.slice_type)"
                :key="index"
                :slice="slice"
            />
        </template>
    </div>
</template>

<script>
import { camelCase, startCase } from 'lodash';
import Vue from 'vue';
const ComponentContext = require.context('@/components/slices', true, /\.vue$/i, 'eager');
ComponentContext.keys().forEach((componentFilePath) => {
    const componentName = componentFilePath.split('/').pop().split('.')[0];
    Vue.component(componentName, () => ComponentContext(componentFilePath));
});

export default {
    props: {
        slices: {
            type: Array,
            required: true,
        }
    },
    methods: {
        toPascalCase(string){
            return startCase(camelCase(string)).replace(/ /g, '');
        }
    }
};
</script>
