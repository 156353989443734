<template>
    <section class="slice bg-kreditly-light">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div 
                        class="text-dark py-4 px-5 px-lg-0 mb-0 text-left" 
                        v-html="$prismic.asHtml(slice.primary.teaser)"
                    />
                </div>
            </div>
            <div class="row">
                <lender-card 
                    v-for="lender in topList" 
                    :key="lender.id" 
                    :lender-id="lender.id"
                />

                <div v-show="!showAll && notShownList.length > 0" class="col-lg-12 text-sm text-dark">
                    <div class="text-center mt-4 mb-4">
                        <a class="btn btn-md btn-primary d-inline-block text-white" @click="showAllLenders()">Visa fler <i class="fa fa-arrow-down" /> </a>
                    </div>
                </div>
            </div>
            <div v-show="showAll" class="row">
                <lender-card 
                    v-for="lender in notShownList" 
                    :key="lender.id" 
                    :lender-id="lender.id"
                />
            </div>
        </div>
    </section>
</template>
<script>
import { map } from 'lodash';
import LenderCard from '@/components/lender-card/LenderCard.vue';
export default {
    components: {
        LenderCard 
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showAll: false
        };
    },
    computed: {
        lenders() {

            if (this.slice.primary.list.id) {

                return map(this.$store.state
                    .lists[this.slice.primary.list.id]
                    .data.lenders,
                'lender').filter(l => l.id);
            }

            return map(this.slice.items, 'lender').filter(l => l.id);
        },
        ordered() {
            return this.$getOrderedList(this.lenders, this.$store.state.lenders, this.$models.Loan);
        },
        topList() {
            return this.ordered.slice(0, 12);
        },
        notShownList() {
            return this.ordered.slice(12);
        }
    },
    methods: {
        showAllLenders() {
            this.showAll = true;
        }
    }
};
</script>