<template>
    <section class="slice bg-kreditly-light mb-5" data-offset-top="#header-main"> 
        <div class="container py-5 pt-lg-7 position-relative zindex-100">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div class="py-5">
                        <h1 class="display-2">{{ $prismic.asText(slice.primary.h1) }}</h1>
                        <h4 class="font-weight-light lh-160" v-html="$prismic.asHtml(slice.primary.h4)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="shape-container" data-shape-style="curve" data-shape-position="bottom" style="height: 50px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" class="injected-svg svg-inject fill-section-white">
                <path d="M 0 0 c 0 0 200 50 500 50 s 500 -50 500 -50 v 101 h -1000 v -100 z" />
            </svg>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>