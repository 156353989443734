<template>
    <section class="slice bg-section-secondary">
        <div class="container">
            <div class="row">
                <div class="col-lg-11">
                    <div v-for="post in posts" :key="post.id" class="row pb-5">
                        <div class="col-3">
                            <nuxt-link :to="$prismic.linkResolver(post)"> 
                                <prismic-image :img="post.data.image" class="lazy img-fluid entered loaded" w="250" />
                            </nuxt-link>
                        </div>
                        <div class="col-9">
                            <h3>
                                <nuxt-link :to="$prismic.linkResolver(post)">
                                    {{ $prismic.asText(post.data.title) }}
                                </nuxt-link>
                            </h3>
                            <span class="badge badge-soft-dark">{{ $format.date(post.first_publication_date) }}</span> 
                            <p class="lead">{{ $prismic.asText(post.data.preamble) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            posts: []
        };
    },
    async fetch() {
        this.posts = await queryAll(this.$prismic.api, [
            this.$prismic.predicates.at('document.type', 'post'),
            this.$prismic.predicates.at('my.post.category', this.slice.primary.category)
        ], {});
    }
};
</script>